@use 'global' as *;
// Disable Stylelint for Marketo Styles
// ---------------------------------------------------------
// stylelint-disable selector-class-pattern, selector-id-pattern
// stylelint-disable selector-pseudo-class-no-unknown, no-descending-specificity

// ---------------------------------------------------------

.newsletter_signup_form {
  input {
    border: 0;
    font-family: var(--font-family-base) !important;
    margin-top: 0 !important;
    position: relative;
  }

  .newsletter_heading {
    grid-area: header;
  }

  a {
    font-size: 14px !important;
  }
}

.form_input {
  grid-area: form;
}

.privacy_notice {
  font-size: 0.8em;
  grid-area: privacy_notice;
  margin-top: 0.7rem;
  max-width: 450px;
}

// ---------------------------------------------------------

.theme_gray_background {
  :global {
    .mkto_container {
      overflow: visible !important;
      padding: 0 !important;

      @include media($medium, down) {
        padding-bottom: 2rem;
      }
    }

    input {
      font-size: 16px !important;
      height: 100%;
      min-width: 250px;
      padding-left: 1rem !important;
      top: 3px;

      &:active,
      &:focus {
        border: none !important;
      }
    }

    label#LblEmail {
      display: none !important;
    }

    .mktoError {
      left: 0 !important;
      margin-top: 20px;
      min-width: 185px;
      position: absolute !important;

      .mktoErrorMsg {
        @include media($small-highest) {
          padding: 0.5rem;
          position: relative;
          z-index: 11;
        }
      }
    }

    .mktoButtonRow {
      margin: 0 !important;
      max-width: fit-content !important;
      position: absolute;
      right: 0;
      top: 3px !important;
    }

    .mktoButton {
      background: transparent !important;
      border: 0 !important;
      color: var(--color-off-black) !important;
      font-family: var(--font-family-headings-bold) !important;
      font-size: 15px !important;

      @include hover {
        background-color: transparent !important;
        color: var(--color-ocean) !important;
      }
    }

    .form-container {
      align-self: flex-start;
      background-color: var(--color-cloud);
      border-radius: 5px;
      max-width: 450px;
    }

    .mktoFormRow {
      width: 100% !important;
    }

    .mktoField {
      background-color: transparent;
      color: var(--color-off-black);

      &::placeholder {
        color: var(--color-slate);
        opacity: 100%;
      }
    }
  }
}

// ---------------------------------------------------------

.theme_white_background {
  :global {
    .form-container {
      padding: 0 !important;
      width: 100%;
    }

    .mkto_container {
      padding: 0 !important;
    }

    .mktoError {
      margin-top: 20px;
      min-width: 185px;
    }

    input {
      border: 1px solid var(--color-cement) !important;
      font-size: 18px !important;

      &:hover {
        border: 1px solid var(--color-prime-teal) !important;
      }

      &:active,
      &:focus {
        border: 1px solid var(--color-ocean) !important;
      }

      &::placeholder {
        color: var(--color-pebble);
      }
    }

    .mktoFieldWrap {
      label {
        display: none !important;
      }

      @include media($medium, down) {
        padding-bottom: 0 !important;
      }
    }

    .mktoButtonRow {
      margin: 0.5rem 0 !important;
    }
  }
}

// ---------------------------------------------------------

.theme_alert_bar {
  :global {
    .mkto_container {
      overflow: visible !important;
      padding: 0 !important;
    }

    input {
      font-size: 14px !important;
      line-height: 1.5 !important;

      &:active,
      &:focus {
        border: none !important;
      }
    }

    label#LblEmail {
      display: none !important;
    }

    .mktoError {
      left: 0 !important;
      position: absolute !important;
      top: 34px !important;
      z-index: var(--z-index-plus-three) !important;
    }

    .mktoButtonRow {
      margin: 0 !important;
      position: absolute;
      right: 0;
      top: 0;
    }

    .mktoButton {
      background: var(--color-white) !important;
      border: 0 !important;
      color: var(--color-lagoon) !important;
      font-family: var(--font-family-headings-bold) !important;
      font-size: 14px !important;

      @include hover {
        background-color: var(--color-white) !important;
        color: var(--color-midnight) !important;
      }
    }

    .form-container {
      background-color: var(--color-white);
      border-radius: 5px;
      height: 42px;
    }

    .mktoField {
      background-color: transparent;
      color: var(--color-off-black);

      &::placeholder {
        color: var(--color-slate);
        opacity: 100%;
      }
    }
  }
}
