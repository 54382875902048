@use 'global' as *;
// ---------------------------------------------------------

.button,
.linktag {
  display: inline-block;

  @include hover {
    text-decoration: none;
  }

  &:disabled {
    border: none;
    cursor: not-allowed;

    @include hover {
      cursor: not-allowed;
    }
  }
}

// --------------------------------------------------------- / themes

.theme {
  &_default {
    @include pill-button;

    background-color: var(--color-amp-yellow);
    border: 1px solid var(--color-off-black);
    color: var(--color-off-black);

    @include hover {
      background-color: var(--color-moss);
    }
  }

  // ------------------------------------------------------

  &_inverted {
    @include pill-button;

    background-color: var(--color-off-black);
    border: 1px solid var(--color-off-black);
    color: var(--color-white);

    @include hover {
      background-color: var(--color-midnight);
      border: 1px solid var(--color-midnight);
    }
  }

  // ------------------------------------------------------

  &_arrow_dark {
    @include arrow;

    @include hover {
      color: var(--color-dusk);

      &::before {
        background: var(--color-dusk);
      }

      &::after {
        border-right: 1.5px solid var(--color-dusk);
        border-top: 1.5px solid var(--color-dusk);
      }
    }

    @include media($small-highest, down) {
      margin-bottom: 20px;
    }
  }

  &_arrow_light {
    @include arrow;

    color: var(--color-ice);

    &::before {
      background: var(--color-ice);
    }

    &::after {
      border-right: 1.5px solid var(--color-ice);
      border-top: 1.5px solid var(--color-ice);
    }

    &:hover {
      color: var(--color-prime-teal);

      &::before {
        background: var(--color-prime-teal);
      }

      &::after {
        border-right: 1.5px solid var(--color-prime-teal) !important;
        border-top: 1.5px solid var(--color-prime-teal) !important;
      }
    }

    &:not(:disabled) {
      @include hover {
        color: var(--color-prime-teal);
      }
    }

    @include media($small-highest, down) {
      margin-bottom: 20px;
    }
  }

  // ------------------------------------------------------

  &_arrow_horizontal_light {
    @include default-button;

    background-color: transparent;
    border: none;
    color: var(--color-ice);

    svg {
      color: var(--color-ice);
      transition: transform 250ms ease;
    }

    @include hover {
      background-color: transparent;
      border: none;
      color: var(--color-prime-teal);

      svg {
        color: var(--color-prime-teal);
        transform: translateX(5px);
      }
    }
  }

  &_arrow_horizontal_dark {
    @include default-button;

    background-color: transparent;
    border: none;
    color: var(--color-dusk);

    svg {
      color: var(--color-dusk);
      transition: transform 250ms ease;
    }

    @include hover {
      background-color: transparent;
      border: none;
      color: var(--color-midnight);

      svg {
        color: var(--color-midnight);
        transform: translateX(5px);
      }
    }
  }

  // ------------------------------------------------------

  &_outline_light {
    @include pill-button;

    background: var(--color-off-black);
    border: 1px solid var(--color-white);
    color: var(--color-white);

    @include hover {
      background: var(--color-midnight);
    }
  }

  &_outline_white {
    @include pill-button;

    background: transparent;
    border: 1px solid var(--color-white);
    color: var(--color-white);

    @include hover {
      background: var(--color-midnight);
    }
  }

  &_outline_dark {
    @include pill-button;

    background: var(--color-white);
    border: 1px solid var(--color-off-black);
    color: var(--color-off-black);

    @include hover {
      background: var(--color-moss);
      border: 1px solid var(--color-off-black);
    }
  }
}

.icon_new_tab {
  display: none;

  @include media($full-navigation) {
    bottom: 1.5px;
    display: inline-block;
    height: 24px;
    margin-left: 0.6rem;
    position: relative;
  }
}

.link_new_tab {
  @include media($full-navigation) {
    width: fit-content;
  }
}
