@use 'global' as *;
.container {
  h3 {
    @include h5;

    @include media($small) {
      padding-top: 2rem;
    }
  }
}

@mixin two-col-grid {
  display: grid !important;
  grid-gap: 10px;
  grid-column-gap: 20px !important;
  grid-template-columns: 1fr 1fr !important;

  > div:nth-of-type(n + 10):nth-of-type(-n + 17) {
    display: none;
  }

  > div:nth-of-type(18),
  > div:nth-of-type(19) {
    grid-column: 1 / 3;
  }

  > div:last-of-type {
    grid-column: 1 / 3;
    justify-self: center;
    position: relative;
    width: 200px;
  }
}

.container_two_col {
  @include container;

  @include media($small-lowest, $medium-lowest) {
    > div > form {
      @include two-col-grid;
    }
  }

  @include media($large-lowest) {
    > div > form {
      @include two-col-grid;
    }
  }
}

.box_shadow {
  padding: 25px 0;

  @include media($small) {
    border-radius: var(--global-border-radius);
    box-shadow: var(--global-box-shadow);
  }
}
