@use 'global' as *;
// --------------------------------------------------------- Variables

$mobile-hover-color: var(--color-prime-teal);
$desktop-hover-color: var(--color-lagoon);

// --------------------------------------------------------- Mixins

// Main dropdown label
@mixin dropdown-label {
  font-family: var(--font-family-headings-bold);
  font-size: 13px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
}

// The "featured" section within a submenu
@mixin featured-section {
  align-items: center;
  background: var(--color-cloud);
  grid-column: 1 / -1;

  @include media($full-navigation, down) {
    background-color: var(--color-anvil);
    border-radius: 0;
    margin-bottom: 15px;

    li a {
      align-items: center;
      display: flex;
    }
  }

  @include media($small-highest, down) {
    height: 90px;
    margin-bottom: 10px;
  }

  @include media(380px, down) {
    a span {
      font-size: 12px;
    }
  }
}

@mixin separator($height, $left, $bottom) {
  &::before {
    background: var(--color-prime-gray);
    bottom: $bottom;
    content: '';
    height: $height;
    left: $left;
    position: absolute;
    width: 1px;
  }
}

// --------------------------------------------------------- Animations for submenu carets

@keyframes slide-arrow-in {
  0% {
    opacity: 0%;
    transform: translate(-50%, 1px);
  }

  100% {
    opacity: 100%;
    transform: translate(-50%, 0);
  }
}

@keyframes slide-arrow-out {
  0% {
    opacity: 100%;
    transform: translate(-50%, 0);
  }

  100% {
    opacity: 0%;
    transform: translate(-50%, 15px);
  }
}

// --------------------------------------------------------- Dropdown

.dropdown {
  margin: 0 !important;
  position: relative;

  @include media($full-navigation, down) {
    border-bottom: 1px solid var(--color-slate);

    button {
      width: 100%;

      ul > li {
        color: var(--color-white);
      }

      > div,
      > ul > li li {
        padding: 0 30px;
      }
    }
  }
}

// --------------------------------------------------------- Featured link with arrow button override

.featured_menu_item {
  width: 100%;

  @include media($full-navigation, down) {
    padding: 1rem 0 !important;

    &:hover,
    &:focus,
    &:active {
      > * {
        color: $mobile-hover-color !important;
      }
    }
  }

  @include media($small-highest, down) {
    padding: 0.5rem 0 !important;
  }
}

.featured_link {
  @include media($full-navigation, down) {
    @include arrow;

    color: var(--color-white);
    font-family: var(--font-family-headings-bold);
    font-size: 16px;
    letter-spacing: 0 !important;
    text-transform: none;
    width: 100%;

    &::before {
      background: var(--color-white) !important;
    }

    &::after {
      border-right: 1.5px solid var(--color-white) !important;
      border-top: 1.5px solid var(--color-white);
    }

    &:hover,
    &:focus,
    &:active {
      &::before {
        background: $mobile-hover-color !important;
      }

      &::after {
        border-right: 1.5px solid $mobile-hover-color !important;
        border-top: 1.5px solid $mobile-hover-color !important;
      }
    }
  }

  @include media($small-lowest, down) {
    font-size: 15px;
  }
}

.link_ai {
  @include hover {
    color: var(--color-deepest-pink);
  }
}

// --------------------------------------------------------- Dropdown label (Platform, Solutions, Company, etc.)

.dropdown_label {
  @include dropdown-label;

  color: var(--color-white);
  cursor: pointer !important;
  line-height: 18.5px;

  &:hover {
    color: var(--color-amp-yellow);
  }

  @include media($full-navigation) {
    display: flex;
    margin: 0 12px;
    outline: none;
    padding: 23px 0;
    transition: color var(--animation-timing) ease-in-out;

    // menu triangle

    &::after {
      border-color: transparent transparent var(--color-white);
      border-style: solid;
      border-width: 0 10px 10px;
      bottom: 0;
      content: '';
      height: 0;
      left: 50%;
      opacity: 0%;
      position: absolute;
      transform: translate(-50%, 20px);
      transition: visibility var(--animation-timing) ease-in-out;
      visibility: hidden;
      width: 0;
      z-index: var(--z-index-plus-one);
    }

    .full_nav_is_showing & {
      color: var(--color-amp-yellow);

      &::after {
        animation: slide-arrow-in 100ms forwards;
        visibility: visible;
      }
    }

    .full_nav_is_hiding & {
      &::after {
        animation: slide-arrow-out 100ms forwards;
      }
    }

    .search_is_showing & {
      color: var(--color-slate);
      pointer-events: none;

      &:hover {
        pointer-events: none;
      }
    }
  }

  @include media($full-navigation, down) {
    display: flex;
    justify-content: space-between;
    margin: auto 15px;
    padding: 28px 1rem 25px !important;
  }
}

.label_is_link {
  @include hover {
    text-decoration: underline;
    text-underline-position: under;
  }
}

// --------------------------------------------------------- Caret svg

.dropdown_label_svg {
  display: block;
  margin-left: 4px;
  margin-top: 1px;
  transform: rotate(0deg);
  transition: transform var(--animation-timing) ease-in-out;

  svg {
    margin-bottom: 3px;
    width: 10px;
  }

  &_is_showing {
    transform: rotate(180deg);
  }

  @include media($full-navigation) {
    display: none;
  }
}

// --------------------------------------------------------- Main dropdown menu

.dropdown_menu {
  min-width: 300px;
  text-align: left;

  @include media($full-navigation, down) {
    margin: 0;
    width: 100%;
  }

  @include media($full-navigation) {
    background-color: var(--color-white);
    border-radius: var(--global-border-radius);
    box-shadow: var(--global-box-shadow);
    display: grid;
    left: 0;
    position: absolute;
    top: var(--global-header-height);
    transition: visibility var(--animation-timing) ease-in-out;
    visibility: hidden;

    li {
      margin: 0;
    }

    .full_nav_is_showing & {
      animation: fade-in var(--animation-timing) forwards;
      visibility: visible;
    }

    .full_nav_is_hiding & {
      animation: fade-out var(--animation-timing) forwards;
    }
  }

  > li {
    @include media($full-navigation) {
      margin-bottom: 0;
      padding: 35px !important;
    }
  }

  // Dropdown menu links

  a {
    align-items: center;
    color: var(--color-off-black);
    cursor: pointer;
    display: flex;
    font-family: var(--font-family-headings-bold);
    font-size: 16px;
    outline: none;
    width: 100%;

    @include media($full-navigation, down) {
      color: var(--color-white);
      line-height: 30px;
      padding: 12px 20px;
    }

    @include media($small-highest, down) {
      font-size: 15px;
      padding: 10px 18px;
    }

    @include hover {
      color: $desktop-hover-color;

      svg {
        fill: $desktop-hover-color !important;
      }

      @include media($full-navigation, down) {
        color: $mobile-hover-color !important;
      }
    }

    // Descriptions for links

    p {
      display: none;

      @include media($full-navigation) {
        cursor: pointer;
        display: block;
        font-family: var(--font-family-base);
        line-height: 20px;
        max-width: 400px;
        padding-top: 4px;
      }
    }
  }

  // ------------------------------------------------------- Columns within each submenu

  .menu_column {
    display: block;

    @include media($full-navigation, down) {
      padding: 10px 0 !important;
    }

    @include media($small-highest, down) {
      ul li {
        padding-left: 1.3rem;
      }
    }

    @include media($full-navigation) {
      ul {
        li {
          margin: 25px 0;
          padding: 0;

          &:first-child {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .menu_column_header {
    @include dropdown-label;

    color: var(--color-pebble);
    cursor: text;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .secondary_menu_column_header {
    margin-top: 1rem;

    @include media($full-navigation) {
      padding-top: 1rem !important;
    }
  }

  // ------------------------------------------------------- Custom styling for each submenu

  .product & {
    @include media($full-navigation) {
      grid-template-columns: 420px 310px;

      .menu_column {
        &:last-of-type {
          @include separator(370px, 55%, 34px);
        }
      }
    }

    > li {
      &:first-of-type {
        border-radius: 5px 5px 0 0;

        @include featured-section;
      }
    }
  }

  .solutions & {
    @include media($full-navigation) {
      grid-template-columns: 265px 250px 250px;

      .menu_column {
        &:nth-of-type(2) {
          @include separator(200px, 36%, 35px);
        }

        &:last-of-type {
          @include separator(200px, 68%, 35px);
        }
      }
    }
  }

  .company & {
    @include media($full-navigation) {
      grid-template-columns: 255px 255px;
      grid-template-rows: 1fr auto;

      .menu_column {
        &:last-of-type {
          @include separator(156px, 50%, 33px);
        }
      }
    }

    > li {
      &:first-of-type {
        @include featured-section;

        border-radius: 5px 5px 0 0;
      }
    }
  }

  .resources & {
    @include media($full-navigation) {
      grid-template-columns: 250px 280px;

      .menu_column {
        &:last-of-type {
          @include separator(158px, 48%, 35px);
        }
      }
    }

    > li {
      &:first-of-type {
        @include featured-section;

        border-radius: 5px 5px 0 0;
      }
    }
  }

  .partners & {
    @include media($full-navigation) {
      grid-template-columns: 280px 280px;
      grid-template-rows: 1fr auto;
      transform: translateX(-15%);

      .menu_column {
        &:nth-of-type(2) {
          @include separator(205px, 50%, 180px);
        }
      }
    }

    @include media($large) {
      transform: none;
    }

    > li {
      &:last-of-type {
        @include featured-section;

        border-radius: 0 0 5px 5px;
      }
    }
  }
}

// --------------------------------------------------------- Nested mobile menu hidden behind accordion

.mobile_accordion_menu {
  @include media($full-navigation, down) {
    height: 0;
    opacity: 0%;
    overflow: hidden;
    transform: translateY(-10%);
    transition: all 150ms;

    &_is_showing {
      height: 100%;
      margin-bottom: 0;
      opacity: 100%;
      padding-bottom: 10px;
      transform: translateY(0);
      transition: all 150ms;
    }
  }
}

// --------------------------------------------------------- Images & icons

.featured_image {
  margin-right: 1.8rem;
  max-width: 80px;
  width: 100%;

  @include media($small-highest, down) {
    margin-right: 1rem;
    width: 65px;
  }

  img {
    border-radius: 8px;
  }
}

.icon_platform {
  display: flex;
  margin-right: 0.8rem;
  width: 30px;

  svg {
    width: 100%;
  }
}

.icon_new_tab {
  display: none;

  @include media($full-navigation) {
    height: 24px;
    margin-left: 0.6rem;
    width: 14px;
  }
}

.link_new_tab {
  @include media($full-navigation) {
    align-items: flex-end;
    display: flex;
  }
}
