// --------------------------------------------------------

@mixin reset-button {
  background-color: transparent;
  border: none;
  outline: none;
}

// --------------------------------------------------------

@mixin reset-list {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
