@use 'global' as *;
.alert_bar {
  display: block;
  font-family: var(--font-family-headings-regular);
  font-size: 16px;
  line-height: 1.5;
  min-height: 50px;
  padding: 10px 15px;
  text-align: center;
  width: 100%;
}

.alert_bar_label {
  cursor: text;
}

.alert_bar_button {
  margin-left: 1.5rem;
}
