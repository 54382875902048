@use 'global' as *;
.header {
  backdrop-filter: blur(5px);
  background: rgb(12 12 12 / 95%);
  height: 100%;
  max-height: var(--global-header-height);
  position: fixed;
  top: 0;
  transition: max-height 200ms ease-in-out;
  width: 100%;
  z-index: var(--z-index-plus-four);

  &.navigation_is_showing {
    max-height: 100vh;
    transition: max-height 200ms ease-in-out;
  }

  @include media($full-navigation) {
    background-color: var(--color-off-black);
    container-type: inline-size;
    height: var(--global-header-height);
    transition: background-color var(--animation-timing) ease-in-out;
  }
}

// ---------------------------------------------------------

.logo {
  svg {
    margin-right: 1rem;
    width: 150px;
  }
}

// ---------------------------------------------------------

.menu_container {
  height: 100%;

  @include media($full-navigation) {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: var(--global-header-height);
    justify-content: space-between;
    margin: auto;
    max-width: 1920px;
    padding: 0 20px;
  }
}

// ---------------------------------------------------------

.header_desktop {
  display: none;

  @include media($full-navigation) {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  @include media($x-large-lowest) {
    justify-content: space-between;
  }

  .header_desktop_left {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .header_desktop_right {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 1rem;
    width: auto;

    @include media($x-large-lowest) {
      position: relative;
    }
  }
}

.header_mobile {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  @include media($full-navigation) {
    display: none;
  }

  .header_mobile_nav {
    align-items: center;
    display: flex;
    height: var(--global-header-height);
    justify-content: space-between;
    min-width: 100%;
    padding: 1rem;
  }
}

// -------------------------------------------------------

.cta_secondary,
.cta_login,
.cta_primary {
  color: var(--color-white);
  cursor: pointer;
  font-family: var(--font-family-headings-bold);
  font-size: 16px;
  white-space: nowrap;
}

.cta_login {
  margin: 0 15px;
  margin-top: -3px;

  @include hover {
    color: var(--color-amp-yellow);
  }
}

.cta_secondary {
  margin: auto 15px auto 10px;
  min-width: fit-content;

  @include media($medium-highest, down) {
    @include pill-button;

    background: var(--color-off-black);
    border: 1px solid var(--color-white);
    color: var(--color-white);
    margin: 1.25rem auto 0;
    text-align: center;
    transition: background-color 250ms ease, color 250ms ease;
    width: 200px;

    @include hover {
      background: var(--color-midnight);
    }
  }

  @include media($medium-highest, $large) {
    display: none;
  }

  @include media($full-navigation) {
    background-color: initial;
    color: var(--color-white);
    margin-left: 0;
    padding: 7px 12px 10px;

    &:hover {
      background-color: initial;
      border-radius: 0;
      color: var(--color-amp-yellow);
    }
  }
}

// ---------------------------------------------------------

.cta_primary {
  background-color: var(--color-prime-teal);
  color: var(--color-off-black);
  margin: 1.2rem auto;
  transition: background-color 250ms ease, color 250ms ease;
  width: 200px;

  @include pill-button;

  &:hover {
    background-color: var(--color-ocean);
  }

  @include media($medium-highest, $large-lowest) {
    display: none;
  }

  @include media($full-navigation) {
    margin: 0;
    width: auto;
  }
}

// ---------------------------------------------------------

.menu_button {
  .menu_button_container {
    --graphic-width: 30px;

    align-content: center;
    cursor: pointer;
    display: flex;
    height: 36px;
    transition: opacity 200ms ease-in-out;
    width: 36px;

    button {
      cursor: pointer;
      display: inline-block;
      position: relative;
      width: 100%;

      .dots {
        align-items: center;
        display: flex;
        height: 80%;
        justify-content: center;

        div .dot,
        div .dot::before,
        div .dot::after {
          background-color: var(--color-white);
          cursor: pointer;
          transform: rotate(270deg);
        }

        div .dot::before,
        div .dot::after {
          content: '';
          position: absolute;
        }

        .circle .dot,
        .circle .dot::before,
        .circle .dot::after {
          border-radius: 20px;
          height: 4px;
          width: 4px;
        }

        .x .dot,
        .x .dot::before,
        .x .dot::after {
          border-radius: none;
          height: 3px;
          width: 3px;
        }

        div .dot::before {
          right: 7px;
          transition: right 0.3s ease-out, width 0.3s ease-out;
        }

        div .dot::after {
          left: 7px;
          transition: left 0.3s ease-out, width 0.3s ease-out;
        }
      }
    }

    .clicked .dots .x .dot::before {
      height: 3px;
      right: -10px;
      transform: rotate(225deg) !important;
      width: 22px;
    }

    .clicked .dots .x .dot::after {
      height: 3px;
      left: -9px;
      transform: rotate(135deg) !important;
      width: 22px;
    }

    @include hover {
      .navigation_is_showing & {
        > div {
          background-color: var(--color-black);
        }
      }
    }

    .navigation_is_showing & {
      > div {
        background-color: var(--color-black);

        &::before {
          top: 0;
          transform: rotate(45deg);
        }

        &::after {
          top: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  button:hover .dots div .dot,
  button:hover .dots div .dot::after,
  button:hover .dots div .dot::before {
    background-color: var(--color-amp-yellow);
  }

  span {
    @include screen-reader-only;
  }
}
