@use 'global' as *;
.has_caption {
  @include caption;
}

.image_hover {
  display: block;
  overflow: hidden;
  transition: box-shadow 250ms ease, transform 250ms ease;
  width: 100%;

  @include hover {
    transform: scale(1.03);
  }
}

.image_container {
  height: auto;

  img {
    height: auto;
  }
}
