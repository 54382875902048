@use 'global' as *;
.navigation_container {
  @include media($full-navigation, down) {
    height: 100%;
    max-height: var(--global-header-height);
    transition: max-height var(--animation-timing) ease-in-out,
      visibility var(--animation-timing) ease-in-out;
    visibility: hidden;
    width: 100%;

    &.navigation_is_showing {
      animation: fade-in var(--animation-timing) forwards;
      max-height: 100vh;
      overflow: auto;
      transition: max-height var(--animation-timing) ease-in-out;
      visibility: visible;
    }

    &.navigation_is_hiding {
      animation: fade-out var(--animation-timing) forwards;
      display: none;
    }
  }
}

.navigation_is_mobile {
  align-items: center;
  background: linear-gradient(0deg, rgb(12 12 12 / 100%) 65%, rgb(12 12 12 / 0%) 100%);
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  text-align: center;
  width: 100%;

  @include media($full-navigation) {
    display: none;
  }
}

// ---------------------------------------------------------

.navigation {
  @include reset-list;

  padding-bottom: 300px;
  padding-top: 1rem;

  @include media($full-navigation) {
    display: inline-flex;
    margin: 0;
    padding: 0 !important;

    // Triangular shape above activated submenu - override default white

    > li:nth-of-type(3),
    > li:nth-of-type(1),
    > li:first-of-type {
      > div > div > button > div {
        &::after {
          border-color: transparent transparent var(--color-cloud) !important;
        }
      }
    }
  }
}

.hide_on_desktop {
  @include media($full-navigation) {
    display: none !important;
  }
}
