@use 'global' as *;
.preview_alert {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 20;
}

.is_homepage {
  h1 {
    @include h1-homepage;
  }
}
