@use 'global' as *;
// ---------------------------------------------------------

.search_container {
  display: flex;
  padding: 0 1rem !important;

  @include media($medium-highest) {
    align-items: center;
    justify-content: space-between;
    margin-right: 0;
    opacity: 90%;
    position: relative;
    transition: width 150ms ease-in-out;
    width: 210px;
  }

  @include media($large) {
    width: 280px;
  }
}

.search_container_active {
  @include media($medium-highest) {
    opacity: 100%;
    position: relative;
    width: var(--global-search-width) !important;
  }
}

// ---------------------------------------------------------

.search_button_container {
  background: var(--color-prime-teal);
  border-radius: 0 5px 5px 0 !important;
  color: white !important;
  cursor: pointer;
  display: flex;
  padding: 12px;
  position: relative;

  svg {
    color: var(--color-off-black);
    width: 20px;
  }

  @include hover {
    background-color: var(--color-ocean);
    cursor: pointer;
  }

  @include media($medium-highest) {
    align-items: center;
    background-color: var(--color-white);
    padding: 8px;
    transition: background-color 150ms ease-in-out;
    z-index: var(--z-index-plus-one);

    @include hover {
      background-color: var(--color-ice) !important;
      cursor: pointer;
    }
  }
}

// ---------------------------------------------------------

.search_input {
  background: var(--color-anvil) !important;
  box-sizing: border-box !important;
  color: var(--color-white) !important;
  height: 44px !important;
  width: 100% !important;

  @include media($medium-highest) {
    background: var(--color-white) !important;
    box-sizing: normal !important;
    color: var(--color-off-black) !important;
    height: 36px !important;
    z-index: var(--z-index-plus-one);
  }
}
