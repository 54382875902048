@use 'global' as *;
// --------------------------------------------------------- /

.footer_content,
.link_lists {
  display: flex;
  flex-direction: row;

  @include media($small, down) {
    display: block;
    flex-direction: initial;
  }

  a {
    color: var(--color-white);
  }
}

.footer_container {
  background-color: var(--color-anvil);
  box-sizing: border-box;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}

.footer_content {
  max-width: var(--global-max-width);

  div[class*='newsletter'] {
    a {
      color: var(--color-ice) !important;

      &:hover {
        color: var(--color-prime-teal) !important;
      }
    }
  }
}

.footer_logo {
  height: 85px;
  margin-right: 4rem;
  margin-top: 2.5rem;
  width: 200px;

  @include media($small, down) {
    height: 50px;
    min-height: 60px;
    min-width: 80px;
    padding: 0;
    width: 150px;
  }
}

// --------------------------------------------------------- / container for input field (Marketo form)

.footer_form {
  align-items: top;
  border-bottom: 1px solid var(--color-pebble);
  column-gap: 2rem;
  display: grid;
  grid-template-areas:
    'header form'
    'header privacy_notice';
  grid-template-columns: 50% 50%;
  margin-top: 38px;
  padding-bottom: 3rem;

  form {
    width: 100%;
  }

  h3 {
    @include media($medium, down) {
      max-width: 100%;
      min-width: 100%;
    }
  }

  @include media($medium, down) {
    display: block;
  }

  @include media($small, down) {
    border-top: 1px solid var(--color-pebble);
    padding-top: 2.5rem;
  }
}

// --------------------------------------------------------- / columns of linked pages

.link_lists {
  font-size: 0.9rem;
  justify-content: space-between;
  margin-top: 2rem;

  .column {
    flex: 1;
    padding-right: 2rem;

    @include media($small, $medium) {
      flex: 40%;
    }
  }

  h4 {
    margin-bottom: 1.5rem;
  }

  ul {
    margin-top: 0.5rem;
    padding-left: 0;

    li {
      font-family: var(--font-family-headings-regular);
      font-size: 1rem;
      font-weight: var(--font-weight-regular);
      line-height: 1.5;
      list-style-type: none;
      margin-bottom: 0.5rem;

      a {
        color: var(--color-white);

        @include hover {
          color: var(--color-amp-yellow);
        }
      }

      .subheader {
        @include h6;

        color: var(--color-gold);
        font-size: 0.7rem;
        margin-bottom: 0.7rem;
      }

      &:not(:first-child) {
        .subheader {
          margin-top: 1.5rem;
        }
      }
    }
  }

  @include media($small, $medium) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

// --------------------------------------------------------- /

.social_media {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;

  a {
    color: var(--color-cloud);
    width: 22px;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    @include hover {
      color: var(--color-moss);
    }
  }

  @include media($small, down) {
    justify-content: center;
    margin-top: 2rem;
  }
}

.copyright {
  color: var(--color-cement);
  font-family: var(--font-family-headings);
  font-size: 0.8rem;
  margin-top: 1rem;
  text-align: left;

  a {
    font-size: 0.8rem;
    font-weight: var(--font-weight-regular);
    letter-spacing: 0;
    text-decoration: none;
    text-transform: capitalize;

    &:hover {
      color: var(--color-amp-yellow);
    }
  }

  @include media($small, down) {
    text-align: center;
  }
}

.preview_margin_bottom {
  margin-bottom: 68px;
}
